import React from 'react';
import styled from 'styled-components';
import Section from '../components/Section';
import Metadata from '../components/Metadata';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-top: 1rem;

    @media (min-width: 576px) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  ul {
    margin-bottom: 1.5rem;
  }

  h2 {
    margin-bottom: 2rem;
  }

  h3 {
    margin-bottom: 1rem;
  }
`;

function Datenschutz() {
  return (
    <>
      <Metadata title='Datenschutz' />
      <StyledContainer>
        <h1>Datenschutzerklärung</h1>
        <Section heading='Geltungsbereich'>
          <p>
            Diese Datenschutzerklärung soll die Nutzer dieser Website gemäß
            Bundesdatenschutzgesetz und Telemediengesetz über die Art, den
            Umfang und den Zweck der Erhebung und Verwendung personenbezogener
            Daten durch den Websitebetreiber Sabrina Liebich, Tonhallenstraße
            19, 47051 Duisburg informieren. Der Websitebetreiber nimmt Ihren
            Datenschutz sehr ernst und behandelt Ihre personenbezogenen Daten
            vertraulich und entsprechend der gesetzlichen Vorschriften. Bedenken
            Sie, dass die Datenübertragung im Internet grundsätzlich mit
            Sicherheitslücken bedacht sein kann. Ein vollumfänglicher Schutz vor
            dem Zugriff durch Fremde ist nicht realisierbar.
          </p>
        </Section>
        <Section heading='Ihre Rechte nach der DSGVO'>
          <p>
            Nach der DSGVO stehen Ihnen die nachfolgend aufgeführten Rechte zu,
            die Sie jederzeit bei dem in Ziffer 1. dieser Datenschutzerklärung
            genannten Verantwortlichen geltend machen können:
          </p>
          <ul>
            <li>
              <strong>Recht auf Auskunft:</strong> Sie haben das Recht, von uns
              Auskunft darüber zu verlangen, ob und welche Daten wir von Ihnen
              verarbeiten.
            </li>
            <li>
              <strong>Recht auf Berichtigung:</strong> Sie haben das Recht, die
              Berichtigung unrichtiger oder Vervollständigung unvollständiger
              Daten zu verlangen.
            </li>
            <li>
              <strong>Recht auf Löschung:</strong> Sie haben das Recht, die
              Löschung Ihrer Daten zu verlangen.
            </li>
            <li>
              <strong>Recht auf Einschränkung:</strong> Sie haben in bestimmten
              Fällen das Recht zu verlangen, dass wir Ihre Daten nur noch
              eingeschränkt bearbeiten.
            </li>
            <li>
              <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das
              Recht zu verlangen, dass wir Ihnen oder einem anderen
              Verantwortlichen Ihre Daten in einem strukturierten, gängigen und
              maschinenlesebaren Format übermitteln.
            </li>
            <li>
              <strong>Beschwerderecht</strong>: Sie haben das Recht, sich bei
              einer Aufsichtsbehörde zu beschweren. Zuständig ist die
              Aufsichtsbehörde Ihres üblichen Aufenthaltsortes, Ihres
              Arbeitsplatzes oder unseres Firmensitzes.
            </li>
          </ul>
          <h3>Widerrufsrecht</h3>
          <p>
            Sie haben das Recht, die von Ihnen erteilte Einwilligung zur
            Datenverarbeitung jederzeit zu widerrufen.
          </p>
          <h3>Widerspruchsrecht</h3>
          <p>
            Sie haben das Recht, jederzeit gegen die Verarbeitung Ihrer Daten,
            die wir auf unser berechtigtes Interesse nach Art. 6 Abs. 1 lit. f
            DSGVO stützen, Widerspruch einzulegen. Sofern Sie von Ihrem
            Widerspruchsrecht Gebrauch machen, bitten wir Sie um die Darlegung
            der Gründe. Wir werden Ihre personenbezogenen Daten dann nicht mehr
            verarbeiten, es sei denn, wir können Ihnen gegenüber nachweisen,
            dass zwingende schutzwürdige Gründe an der Datenverarbeitung Ihre
            Interessen und Rechte überwiegen.
          </p>
          <p>
            <span style={{ textDecoration: 'underline' }}>
              <strong>
                Unabhängig vom vorstehend Gesagten, haben Sie das jederzeitige
                Recht, der Verarbeitung Ihrer personenbezogenen Daten für Zwecke
                der Werbung und Datenanalyse zu widersprechen.
              </strong>
            </span>
          </p>
          <p>
            Ihren Widerspruch richten Sie bitte an die oben angegebene
            Kontaktadresse des Verantwortlichen.
          </p>
          <h2>Wann löschen wir Ihre Daten?</h2>
          <p>
            Wir löschen Ihre Daten dann, wenn wir diese nicht mehr brauchen oder
            Sie uns dies vorgeben. Das bedeutet, dass - sofern sich aus den
            einzelnen Datenschutzhinweisen dieser Datenschutzerklärung nichts
            anderes ergibt - wir Ihre Daten löschen,
          </p>
          <ul>
            <li>
              wenn der Zweck der Datenverarbeitung weggefallen ist und damit die
              jeweilige in den einzelnen Datenschutzhinweisen genannte
              Rechtsgrundlage nicht mehr besteht, also bspw.
              <ul>
                <li>
                  nach Beendigung der zwischen uns bestehenden vertraglichen
                  oder mitgliedschaftlichen Beziehungen (Art. 6 Abs. 1 lit. a
                  DSGVO) oder
                </li>
                <li>
                  nach Wegfall unseres berechtigten Interesses an der weiteren
                  Verarbeitung oder Speicherung Ihrer Daten (Art. 6 Abs. 1 lit.
                  f DSGVO),
                </li>
              </ul>
            </li>
            <li>
              wenn Sie von Ihrem Widerrufsrecht Gebrauch machen und keine
              anderweitige gesetzliche Rechtsgrundlage für die Verarbeitung im
              Sinne von Art. 6 Abs. 1 lit. b-f DSGVO eingreift,
            </li>
            <li>
              wenn Sie vom Ihrem Widerspruchsrecht Gebrauch machen und der
              Löschung keine zwingenden schutzwürdigen Gründe entgegenstehen.
            </li>
          </ul>
          <p>
            Sofern wir (bestimmte Teile) Ihre(r) Daten jedoch noch für andere
            Zwecke vorhalten müssen, weil dies etwa steuerliche
            Aufbewahrungsfristen (in der Regel 6 Jahre für
            Geschäftskorrespondenz bzw. 10 Jahre für Buchungsbelege) oder die
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen aus
            vertraglichen Beziehungen (bis zu vier Jahren) erforderlich machen
            oder die Daten zum Schutz der Rechte einer anderen natürlichen oder
            juristischen Person gebraucht werden, löschen wir (den Teil) Ihre(r)
            Daten erst nach Ablauf dieser Fristen. Bis zum Ablauf dieser Fristen
            beschränken wir die Verarbeitung dieser Daten jedoch auf diese
            Zwecke (Erfüllung der Aufbewahrungspflichten).
          </p>
        </Section>
        <Section heading='Cookies'>
          <p>
            Diese Website verwendet Cookies. Dabei handelt es sich um kleine
            Textdateien, welche auf Ihrem Endgerät gespeichert werden. Ihr
            Browser greift auf diese Dateien zu. Durch den Einsatz von Cookies
            erhöht sich die Benutzerfreundlichkeit und Sicherheit dieser
            Website. Gängige Browser bieten die Einstellungsoption, Cookies
            nicht zuzulassen. Hinweis: Es ist nicht gewährleistet, dass Sie auf
            alle Funktionen dieser Website ohne Einschränkungen zugreifen
            können, wenn Sie entsprechende Einstellungen vornehmen.
          </p>
        </Section>
        <Section heading='Webhosting'>
          <p>
            Wir bedienen uns zum Vorhalten unserer Internetseiten eines
            Anbieters, auf dessen Server unsere Internetseiten gespeichert und
            für den Abruf im Internet verfügbar gemacht werden (Hosting).
            Hierbei können von dem Anbieter all diejenigen über den von Ihnen
            genutzten Browser übertragenen Daten verarbeitet werden, die bei der
            Nutzung unserer Internetseiten anfallen. Hierzu gehören insbesondere
            Ihre IP-Adresse, die der Anbieter benötigt, um unser Online-Angebot
            an den von Ihnen genutzten Browser ausliefern zu können sowie
            sämtliche von Ihnen über unsere Internetseite getätigten Eingaben.
            Daneben kann der von uns genutzte Anbieter&nbsp;&nbsp;
          </p>
          <ul>
            <li>
              das Datum und die Uhrzeit des Zugriffs auf unsere Internetseite
            </li>
            <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
            <li>Zugriffsstatus (HTTP-Status)</li>
            <li>die übertragene Datenmenge</li>
            <li>der Internet-Service-Provider des zugreifenden Systems</li>
            <li>der von Ihnen verwendete Browsertyp und dessen Version</li>
            <li>das von Ihnen verwendete Betriebssystem</li>
            <li>
              die Internetseite, von welcher Sie gegebenenfalls auf unsere
              Internetseite gelangt sind
            </li>
            <li>
              die Seiten bzw. Unterseiten, welche Sie auf unserer Internetseite
              besuchen.
            </li>
          </ul>
          <p>
            erheben. Die vorgenannten Daten werden als Logfiles auf den Servern
            unseres Anbieters gespeichert. Dies ist erforderlich, um die
            Stabilität und Sicherheit des Betriebs unserer Internetseite zu
            gewährleisten.
          </p>
          <p class='invisible'>&nbsp;</p>
          <p>
            <strong>Betroffene Daten:</strong>
          </p>
          <ul>
            <li>Inhaltsdaten (bspw. Posts, Fotos, Videos)</li>
            <li>Nutzungsdaten (bspw. Zugriffszeiten, angeklickte Webseiten)</li>
            <li>
              Kommunikationsdaten (bspw. Informationen über das genutzte Gerät,
              IP-Adresse)
            </li>
          </ul>
          <p>
            <strong>Betroffene Personen: </strong>Nutzer unserer Internetpräsenz
          </p>
          <p>
            <strong>Verarbeitungszweck: </strong>Ausspielen unserer
            Internetseiten, Gewährleistung des Betriebs unserer Internetseiten
          </p>
          <p>
            <strong>Rechtsgrundlage:</strong> Berechtigtes Interesse, Art. 6
            Abs. 1 lit. f DSGVO
          </p>
          <p>
            <strong>Von uns beauftragte(r) Webhoster:</strong>
          </p>
          <p>
            <strong>1&amp;1 Webhosting</strong>
          </p>
          <p>
            Dienstanbieter: 1&amp;1 Telecommunication SE, Elgendorfer Str. 57,
            56410 Montabaur
            <br />
            Internetseite:
            <br />
            <a href='https://www.1und1.de/'>https://www.1und1.de/</a>
            <br />
            Datenschutzerklärung:
            <br />
            <a href='https://www.1und1.de/Datenschutz'>
              https://www.1und1.de/Datenschutz
            </a>
          </p>
        </Section>
        <Section heading='Zugriffsdaten'>
          <p>
            Der Websitebetreiber bzw. Seitenprovider erhebt Daten über Zugriffe
            auf die Seite und speichert diese als „Server-Logfiles“ ab. Folgende
            Daten werden so protokolliert:
          </p>
          <ul>
            <li>Besuchte Website</li>
            <li>Uhrzeit zum Zeitpunkt des Zugriffes</li>
            <li>Menge der gesendeten Daten in Byte</li>
            <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
            <li>Verwendeter Browser</li>
            <li>Verwendetes Betriebssystem</li>
            <li>Verwendete IP-Adresse</li>
          </ul>
          <p>
            Die erhobenen Daten dienen lediglich statistischen Auswertungen und
            zur Verbesserung der Website. Der Websitebetreiber behält sich
            allerdings vor, die Server-Logfiles nachträglich zu überprüfen,
            sollten konkrete Anhaltspunkte auf eine rechtswidrige Nutzung
            hinweisen.
          </p>
        </Section>
        <Section heading='Umgang mit personenbezogenen Daten'>
          <p>
            Der Websitebetreiber erhebt, nutzt und gibt Ihre personenbezogenen
            Daten nur dann weiter, wenn dies im gesetzlichen Rahmen erlaubt ist
            oder Sie in die Datenerhebung einwilligen. Als personenbezogene
            Daten gelten sämtliche Informationen, welche dazu dienen, Ihre
            Person zu bestimmen und welche zu Ihnen zurückverfolgt werden können
            – also beispielsweise Ihr Name, Ihre E-Mail-Adresse und
            Telefonnummer. Sämtliche Daten, welche im Rahmen einer
            Bestellabwicklung von den Kunden eingegeben werden, werden
            gespeichert. Dazu gehören:
          </p>
          <ul>
            <li>Name, Vorname</li>
            <li>Adresse</li>
            <li>Zahldaten</li>
            <li>E-Mail-Adresse</li>
          </ul>
          <p>
            Jene Daten, welche zur Auslieferung oder Auftragsabwicklung zwingend
            notwendig sind, werden an dritte Dienstleister weitergegeben. Sowie
            die Aufbewahrung Ihrer Daten nicht mehr erforderlich oder gesetzlich
            geboten ist, werden diese gelöscht.
          </p>
        </Section>
        <Section heading='Online-Kurse/Webinare über Drittanbieter'>
          <p>
            Wir bieten Ihnen über unsere Webseite auch digitale Produkte wie
            Online-Kurse/Webinare, digitale Produkte und Download-Produkte zum
            Kauf an. Dafür nutzen wir den Dienst elopage. Sobald Sie auf einen
            unserer Produktbuttons klicken, verlassen Sie unsere Webseite und
            werden auf unsere individuelle elopage-Verkaufsseite weitergeleitet.
            elopage ist ein Dienst der:
          </p>
          <span>elopage GmbH</span>
          <br />
          <span>Kurfürstendamm 182</span>
          <br />
          <span>10707 Berlin</span>
          <br />
          <p>
            Sämtliche Funktionen auf der Verkaufsseite sowie auch die gesamte
            nachgelagerte Verkaufsabwicklung erfolgen über elopage. Die
            Datenschutzerklärung von elopage finden Sie
            <a href='https://elopage.com/privacy'> hier</a>.
          </p>
          <p>
            Auf unserer Verkaufswebseite von elopage haben wir eine gesonderte
            Datenschutzerklärung, die Sie bitte ebenfalls beachten. Wir haben
            mit der elopage GmbH als unserem Auftragsverarbeiter einen
            entsprechenden Vertrag nach Art. 28 DSGVO abgeschlossen. Die
            Rechtsgrundlage zur Verarbeitung der personenbezogenen Daten bei der
            Weiterleitung von unserer Webseite zu der Verkaufsseite über elopage
            ergibt sich vorliegend aus Art. 6 Abs. 1 S. 1 lit. b.
          </p>
        </Section>
        <Section heading='Newsletter'>
          <p>
            Wenn Se sich für unseren Newsletter anmelden nutzen wir die
            Anmeldedaten ausschließlich für den Newsletter-Dienst. Angemeldete
            Nutzer können von uns kontaktiert werden um über Änderungen,
            Aktualisierungen oder andere relevante Informationen unterrichtet zu
            werden.
          </p>
          <p>
            Bei der Anmeldung muss eine gültige E-Mail-Adresse angegeben werden.
            Über das sogenannte „double-opt-in“ Verfahren wird geprüft, ob
            Anmelder und E-Mail übereinstimmen.
          </p>
          <p>
            Sie haben das Recht, ihre Daten für den Newsletterdienst jederzeit
            löschen zu lassen. In unseren Newslettern finden Sie einen Link zur
            Abmeldung. Außerdem können Sie sich auf der Webseite abmelden oder
            uns ihren Wunsch über unsere Kontaktseite mitteilen.
          </p>
        </Section>
        <Section heading='Sicherheitsmaßnahmen'>
          <p>
            Wir treffen im Übrigen technische und organisatorische
            Sicherheitsmaßnahmen nach dem Stand der Technik, um die Vorschriften
            der Datenschutzgesetze einzuhalten und Ihre Daten gegen zufällige
            oder vorsätzliche Manipulationen, teilweisen oder vollständigen
            Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu
            schützen.
          </p>
        </Section>
        <Section heading='Änderung der Datenschutzbestimmungen'>
          <p>
            Unsere Datenschutzerklärung können in unregelmäßigen Abständen
            angepasst werden, damit sie den aktuellen rechtlichen Anforderungen
            entsprechen oder um Änderungen unserer Dienstleistungen umzusetzen,
            z. B. bei der Einfügung neuer Angebote. Für Ihren nächsten Besuch
            gilt dann automatisch die neue Datenschutzerklärung.
          </p>
        </Section>
        <Section heading='Fragen'>
          <p>
            Für Fragen zum Datenschutz schicken Sie uns bitte eine Nachricht an{' '}
            <a href='mailto:info@lernen-rueckenwind.de'>
              info@lernen-rueckenwind.de{' '}
            </a>
            mit dem Betreff „Datenschutz“.
          </p>
        </Section>
      </StyledContainer>
    </>
  );
}

export default Datenschutz;
